import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import IconTextBlock from '../../molecules/IconTextBlock/IconTextBlock';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';

const IconTextBlockSection = ({ iconTextBlocks, sectionStyles }) => {
  const iconTextBlockSectionCss = css`
    margin: 0 auto;
    padding: ${uc('10px 0 40px')};
    text-align: center;

    .iconTextBlock {
      margin: ${uc('70px 44px 0')};

      @media (${breakpoints.mobile}) {
        margin: 0 auto;
      }

      h4 {
        margin-top: ${uc('30px')};
        margin-bottom: ${uc('20px')};
        color: ${colors.darkGray.two};
        font-weight: ${fontWeights.bold};
        font-family: ${fontFamilies.proximaSoft};
      }

      p {
        color: ${colors.darkGray.two};
        font-weight: ${fontWeights.normal};
        font-size: ${fontSizes.sixteen};
        font-family: ${fontFamilies.roboto};
      }

      div {
        width: ${uc('125px')};
        margin: ${uc('20px auto 5px')};

        img {
          margin: auto;
          border-radius: 50%;
          box-shadow: ${shadows.small};
        }
      }
    }
  `;

  const renderedIconTextBlocks = iconTextBlocks.map(iconTextBlock => (
    <IconTextBlock
      key={`icontextblock-component-${iconTextBlock._key}`}
      className="iconTextBlock"
      {...iconTextBlock}
    />
  ));

  return (
    <Section sectionStyles={sectionStyles}>
      <div css={iconTextBlockSectionCss}>{renderedIconTextBlocks}</div>
    </Section>
  );
};

IconTextBlockSection.propTypes = {
  iconTextBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

IconTextBlockSection.defaultProps = {
  sectionStyles: {},
};

export default IconTextBlockSection;
