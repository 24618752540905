import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import { Heading4 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import {
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';

const IconTextBlock = ({
  className,
  description,
  experienceImage,
  headingText,
}) => {
  const iconTextBlockCss = css`
    display: inline-block;
    width: ${uc('310px')};
    vertical-align: top;
  `;

  const imageDivCss = css`
    display: flex;
    align-items: center;
    width: ${uc('105px')};
  `;

  const textCss = css`
    line-height: ${lineHeights.copy};
  `;

  return (
    <div css={iconTextBlockCss} className={className}>
      <div css={imageDivCss}>
        <Image
          src={sanityImage(experienceImage)
            .auto('format')
            .url()}
          alt="icon image"
          title="icon image"
        />
      </div>
      <Heading4 fontSize={fontSizes.eighteen}>{headingText}</Heading4>
      <Text css={textCss}>{description}</Text>
    </div>
  );
};

IconTextBlock.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  description: PropTypes.string,
  experienceImage: PropTypes.shape({}).isRequired,
  headingText: PropTypes.string,
};

IconTextBlock.defaultProps = {
  className: '',
  description: '',
  headingText: '',
};

export default IconTextBlock;
